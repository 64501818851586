<template>
  <v-form class="form"
          v-model="validForm"
          @submit.prevent="submitForm"
          ref="form">
    <div class="mobile-title">
      {{ $t('stepLicense') }}
    </div>
    <v-row class="mb-1 mt-1">
      <v-col cols="12" sm="12" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldLicenseNumberLabel') }}
          </div>
          <div class="form-field__body">
            <v-text-field
              v-model="payload.licenseNumber"
              class="mt-0 pt-0"
              prepend-inner-icon="mdi-pencil"
              name="name"
              type="text"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldPhotoFrontLabel') }}
          </div>
          <div class="form-field__body">
            <ImageFile
              image-id="licensePhotoFront"
              :image="payload.licensePhotoFront"
              v-model="payload.licensePhotoFront">
            </ImageFile>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldPhotoBackLabel') }}
          </div>
          <div class="form-field__body">
            <ImageFile
              image-id="licensePhotoBack"
              :image="payload.licensePhotoBack"
              v-model="payload.licensePhotoBack">
            </ImageFile>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldIdConfirmationLabel') }}
          </div>
          <div class="form-field__body">
            <ImageFile
              image-id="licensePhotoNFace"
              :quality-hint="$t('idConfirmHint')"
              :image="payload.licensePhotoNFace"
              v-model="payload.licensePhotoNFace">
            </ImageFile>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between align-center">
      <v-btn
        small
        color="silver"
        @click="$emit('backward')"
      >
        <v-icon left small>
          mdi-chevron-left
        </v-icon>
        {{ $t('back') }}
      </v-btn>
      <div>
        <v-btn
          small
          color="silver"
          class="mr-2 mr-sm-3"
          @click="$emit('forward')"
        >
          {{ $t('skip') }}
          <v-icon right small>
            keyboard_double_arrow_right
          </v-icon>
        </v-btn>
        <v-btn
          small
          type="submit"
          :loading="loading"
          color="secondary"
        >
          {{ $t('continue') }}
          <v-icon right small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
    </div>
  </v-form>
</template>
<script>
import ImageFile from "@/components/features/ImageFile.vue";
import validation from '@/mixins/validation'
import apiMedia from "@/api/media";

export default {
  mixins: [validation],
  components: {
    ImageFile
  },
  data() {
    return {
      validForm: false,
      loading: false,
      payload: {
        licenseNumber: '',
        licensePhotoFront: '',
        licensePhotoBack: '',
        licensePhotoNFace: '',
      }
    }
  },
  methods: {
    async uploadMedia(base64){
      const imgLink = await apiMedia.upload(base64)
      return imgLink
    },
    async submitForm() {
      this.$refs.form.validate()
      if (this.validForm) {
        try {
          this.loading = true
          if (this.payload.licensePhotoFront.includes('base64')) {
            this.payload.licensePhotoFront = await this.uploadMedia(this.payload.licensePhotoFront)
          }
          if (this.payload.licensePhotoBack.includes('base64')) {
            this.payload.licensePhotoBack = await this.uploadMedia(this.payload.licensePhotoBack)
          }
          if (this.payload.licensePhotoNFace.includes('base64')) {
            this.payload.licensePhotoNFace = await this.uploadMedia(this.payload.licensePhotoNFace)
          }
          this.$emit('submit', this.payload)
          this.$emit('forward')
        }finally {
          this.loading = false
        }
      }
    },
  }
}
</script>
