<template>
  <v-form class="form"
          v-model="validForm"
          @submit.prevent="submitForm"
          ref="form">
    <div class="mobile-title">
      {{ $t('stepAcceptance') }}
    </div>
    <v-row class="mb-1 mt-5">
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldCountryDrivingLabel') }}
          </div>
          <div class="form-field__body">
            <v-select
              v-model="payload.countryId"
              :placeholder="$t('chooseOptions')"
              class="mt-0 pt-0 m-select"
              :items="countries"
              :error-messages="serverErrors.countryId"
              item-value="id"
              item-text="name"
              append-icon="mdi-chevron-down"
              :rules="required"
              name="country"
            ></v-select>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldSignatureLabel') }}
          </div>
          <div class="form-field__body datepicker">
            <v-text-field
              v-model="payload.signature"
              :error-messages="serverErrors.signature"
              class="mt-0 pt-0"
              :rules="required"
              prepend-inner-icon="mdi-pencil"
              name="signature"
              type="text"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="terms-container">
          <h1 class="mb-3">{{ $t('despaxoDeliveryPolicySlogan1') }}</h1>
          <div class="agreements-text">
            <p>{{ $t('despaxoDeliveryPolicySlogan2') }}</p>
            <h3>{{ $t('despaxoDeliveryPolicySlogan3') }}</h3>
            <p>{{ $t('despaxoDeliveryPolicySlogan4') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan5') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan6') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan7') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan8') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan9') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan10') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan11') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan12') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan13') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan14') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan15') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan16') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan17') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan18') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan19') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan20') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan21') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan22') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan23') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan24') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan25') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan26') }}</p>
            <p v-html="$t('despaxoDeliveryPolicySlogan27')"></p>
            <p>{{ $t('despaxoDeliveryPolicySlogan28') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan29') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan30') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan31') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan32') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan33') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan34') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan35') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan36') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan37') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan38') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan39') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan41') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan42') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan43') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan44') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan45') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan46') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan47') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan48') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan49') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan50') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan51') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan52') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan53') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan54') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan55') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan56') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan57') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan58') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan59') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan60') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan61') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan62') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan63') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan64') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan65') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan66') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan67') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan68') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan69') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan70') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan71') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan72') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan73') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan74') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan75') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan76') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan77') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan78') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan79') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan80') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan81') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan82') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan83') }}</p>
            <p>{{ $t('despaxoDeliveryPolicySlogan84') }}</p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <v-checkbox
          class="mt-0"
          v-model="payload.agreementAccepted"
          :rules="required">
          <template #label>
            {{ $t('acceptAgreement') }}
          </template>
          >
        </v-checkbox>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between align-center mt-4">
      <v-btn
        small
        color="silver"
        @click="$emit('backward')"
      >
        <v-icon left small>
          mdi-chevron-left
        </v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-btn
        small
        type="submit"
        :loading="loading"
        color="secondary"
      >
        {{ $t('create') }}
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import validation from '@/mixins/validation'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    countries: {
      type: Array,
      default: () => []
    }
  },
  mixins: [validation],
  components: {},
  data() {
    return {
      serverErrors: {},
      validForm: false,
      showAgreement: false,
      payload: {
        countryId: '',
        signature: '',
        agreementAccepted: false,
      }
    }
  },
  methods: {
    submitForm() {
      this.serverErrors = {}
      this.$refs.form.validate()
      if (this.validForm) {
        this.$emit('submit', this.payload)
      }
    },
    handleDialogAgreements() {
      this.showAgreement = !this.showAgreement
    }
  }
}
</script>
<style lang="scss">
.terms-container{
  width: 100%;
  height: 45vh;
  min-height: 250px;
  margin-bottom: 30px;
  overflow-y: auto;
  padding: 15px;
  border: 1px solid #44b081;
  h1{
    @include respondTo(600px){
      font-size: 26px;
    }
  }
  p{
    font-family: $mont !important;
    font-size: 16px;
    line-height: 1.4;
    @include respondTo(600px){
      font-size: 14px;
      line-height: 1.3;
    }
  }
}
</style>
