<template>
  <v-form class="form"
          v-model="validForm"
          @submit.prevent="submitForm"
          ref="form">
    <div class="mobile-title">
      {{ $t('stepPoliceReport') }}
    </div>
    <v-row class="mb-1 mt-1">
      <v-col cols="12" sm="12" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('backgroundCheck') }}
          </div>
          <div class="form-field__body">
            <ImageFile
              image-id="backgroundCheck"
              :image="payload.backgroundCheck"
              v-model="payload.backgroundCheck">
            </ImageFile>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between align-center">
      <v-btn
        small
        color="silver"
        @click="$emit('backward')"
      >
        <v-icon left small>
          mdi-chevron-left
        </v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-btn
        small
        type="submit"
        :disabled="!payload.backgroundCheck"
        :loading="loading"
        color="secondary"
      >
        {{ $t('continue') }}
        <v-icon right small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import ImageFile from "@/components/features/ImageFile.vue";
import validation from '@/mixins/validation'
import apiMedia from "@/api/media";

export default {
  mixins: [validation],
  components: {
    ImageFile
  },
  data() {
    return {
      serverErrors: {},
      loading: false,
      validForm: false,
      payload: {
        backgroundCheck: '',
      }
    }
  },
  methods: {
    async uploadMedia(base64){
      return await apiMedia.upload(base64)
    },
    async submitForm() {
      this.serverErrors = {}
      this.$refs.form.validate()
      if (this.validForm) {
        try {
          this.loading = true
          if (this.payload.backgroundCheck.includes('base64')) {
            this.payload.backgroundCheck = await this.uploadMedia(this.payload.backgroundCheck)
          }
          this.$emit('submit', this.payload)
          this.$emit('forward')
        }finally {
          this.loading = false
        }
      }
    },
  }
}
</script>
