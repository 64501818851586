<template>
  <div class="pa-1 white full-width pb-4">
    <v-form class="form"
            v-model="validForm"
            @submit.prevent="submitForm"
            ref="form">
      <div class="text-center" style="font-size: 26px; font-weight: 600;">
        {{ $t('confirmOtp') }}
      </div>
      <p class="mt-3" style="line-height: 1.4;">
        {{ $t('confirmEmailAddressHint') }}
      </p>
      <v-row class="mb-1 mt-5 ">
        <v-col cols="12" class="pt-0 pb-0">
          <div class="form-field">
            <div class="form-field__label">
              {{ $t('fieldConfirmCodeLabel') }}
            </div>
            <div class="form-field__body">
              <v-text-field
                v-model="code"
                :error-messages="serverErrors?.code || serverErrors?.type && $t(`serverError_${serverErrors?.type}`)"
                class="mt-0 pt-0"
                :rules="codeRules"
                placeholder="######"
                name="code"
                type="number"
                hide-details="auto"
                @blur="$emit('reset-errors')"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center align-center mt-4">
        <v-btn
          type="submit"
          :loading="loading"
          color="secondary"
        >
          {{ $t('confirm') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import validation from '@/mixins/validation'

export default {
  props: {
    serverErrors: {},
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [validation],
  emits: ['submit', 'reset-errors'],
  data() {
    return {
      validForm: false,
      code: ''
    }
  },
  methods: {
    submitForm() {
      this.$refs.form.resetValidation()
      this.$emit('reset-errors')
      setTimeout(() => {
        this.$refs.form.validate()
        if (this.validForm) {
          this.$emit('submit', this.code)
        }
      }, 10)
    }
  },
  beforeDestroy() {
    this.$refs.form.resetValidation()
    this.$emit('reset-errors')
  }
}
</script>
<style lang="scss">
.agreement-text{
  p{
    font-family: $mont !important;
    font-size: 16px;
    line-height: 1.4;
    @include respondTo(600px){
      font-size: 14px;
      line-height: 1.3;
    }
  }
}
</style>
