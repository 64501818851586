<template>
  <v-form class="form"
          v-model="validForm"
          @submit.prevent="submitForm"
          ref="form">
    <div class="mobile-title">
      {{ $t('stepYourInfo') }}
    </div>
    <v-row class="mb-1">
      <v-col cols="12" sm="12" class="pt-0 pb-0 mt-4">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldProfilePhotoLabel') }}
          </div>
          <div class="form-field__body">
            <ImageFile
              image-id="avatarLink"
              :image="payload.avatarLink"
              v-model="payload.avatarLink">
            </ImageFile>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldFirstNameLabel') }}
          </div>
          <div class="form-field__body">
            <v-text-field
              v-model="payload.firstName"
              :error-messages="serverErrors?.firstName"
              class="mt-0 pt-0"
              prepend-inner-icon="mdi-pencil"
              :rules="required"
              name="name"
              type="text"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldLastNameLabel') }}
          </div>
          <div class="form-field__body">
            <v-text-field
              v-model="payload.lastName"
              :rules="required"
              :error-messages="serverErrors?.lastName"
              class="mt-0 pt-0"
              prepend-inner-icon="mdi-pencil"
              name="name"
              type="text"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 pb-0 mb-2">
        <div class="form-field">
          <div class="form-field__label mb-1">
            {{ $t('fieldPhoneLabel') }}
          </div>
          <div class="form-field__body">
            <PhoneInput
              :value="payload.phone"
              :errors="phoneErrors"
              @on-update="payload.phone = $event"
              @valid="validPhone = $event"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field mb-5">
          <div class="form-field__label">
            {{ $t('fieldEmailLabel') }}
          </div>
          <div class="form-field__body">
            <v-text-field
              v-model.trim="payload.email"
              :error-messages="serverErrors?.email"
              class="mt-0 pt-0"
              :rules="emailRules"
              prepend-inner-icon="mdi-pencil"
              name="email"
              type="text"
            />
            <small style="font-size: 12px; line-height: 1.1; display: block; color: #999">
              {{ $t('emailWarning') }}
            </small>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldNicknameLabel') }}
          </div>
          <div class="form-field__body">
            <v-text-field
              v-model="payload.nickname"
              :error-messages="serverErrors?.nickname"
              class="mt-0 pt-0"
              prepend-inner-icon="mdi-pencil"
              name="nickname"
              :rules="required"
              type="text"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldBirthLabel') }}
          </div>
          <div class="form-field__body datepicker">
            <v-menu
              v-model="openDatepicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="payload.birthDate"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  class="mt-0 pt-0"
                  :rules="required"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="payload.birthDate"
                min="1940-01-01"
                max="2013-01-01"
                @input="openDatepicker = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldDriverTypeLabel') }}
          </div>
          <div class="form-field__body">
            <v-select
              v-model="payload.driverType"
              :items="driverTypes"
              :rules="requiredSelect"
              append-icon="mdi-chevron-down"
              class="mt-0 pt-0 m-select"
              item-text="labelKey"
              item-value="id"
              name="driverTypes"
            ></v-select>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-end flex-wrap">
      <v-btn
        small
        outlined
        class="mb-3"
        color="secondary"
        @click="showConfirmEmail = true"
      >
        {{ $t('iHaveHolaProfile') }}
      </v-btn>
      <v-btn
        small
        type="submit"
        class="ml-5"
        :disabled="!payload.avatarLink || !validPhone"
        :loading="loading || updatePhoneLoading"
        color="secondary"
      >
        {{ $t('continue') }}
        <v-icon right small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
    <v-dialog
      v-model="showConfirmOpt"
      width="400"
    >
      <ConfirmOtp
        v-if="showConfirmOpt"
        :loading="loadingOtp"
        :server-errors="confirmOtpServerErrors"
        @submit="submitOtp"
        @reset-errors="confirmOtpServerErrors = null"
      />
    </v-dialog>
    <v-dialog
      v-model="showConfirmEmail"
      width="400"
    >
      <ConfirmEmail
        v-if="showConfirmEmail"
        :loading="loadingEmail"
        @submit="submitEmail"
      />
    </v-dialog>
  </v-form>
</template>
<script>
import ConfirmEmail from "./ConfirmEmail.vue";
import ConfirmOtp from "./ConfirmOtp.vue";
import ImageFile from "@/components/features/ImageFile.vue";
import PhoneInput from "@/components/atoms/PhoneInput.vue";
import validation from '@/mixins/validation'
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
import apiMedia from '@/api/media'

const device = {
  id: '73617948-6f6c-6120-426c-6f6720777000',
  type: 'web'
}

const driverTypes = function () {
  return [
    {
      id: 0,
      labelKey: this.$t('provideTripsWithMyCar')
    },
    {
      id: 1,
      labelKey: this.$t('someoneToDriveMyVehicle')
    },
    {
      id: 2,
      labelKey: this.$t('driveButDontHaveCar')
    },
    {
      id: 3,
      labelKey: this.$t('deliverOnMoto')
    },
    {
      id: 4,
      labelKey: this.$t('deliverOnBicycle')
    },
    {
      id: 5,
      labelKey: this.$t('deliverSmallFreight')
    },
    {
      id: 6,
      labelKey: this.$t('deliverLargeFreight')
    },
    {
      id: 7,
      labelKey: this.$t('offerAllTerrainRides')
    },
    {
      id: 8,
      labelKey: this.$t('offerGroupsSmallShuttleRides')
    },
    {
      id: 9,
      labelKey: this.$t('offerRidesByMoto')
    }
  ]
}

export default {
  mixins: [validation],
  components: {
    ConfirmEmail,
    ConfirmOtp,
    ImageFile,
    PhoneInput
  },
  data() {
    return {
      serverErrors: {},
      confirmOtpServerErrors: null,
      openDatepicker: false,
      validPhone: false,
      loadingEmail: false,
      loadingOtp: false,
      loading: false,
      validForm: false,
      localePicker: 'en-US',
      showConfirmEmail: false,
      showConfirmOpt: false,
      hasHolaProfile: false,
      payload: {
        accountTypes: [101],
        phone: '',
        driverType: '',
        avatarLink: '',
        firstName: '',
        lastName: '',
        nickname: '',
        birthDate: moment().format('YYYY-MM-DD'),
        email: ''
      }
    }
  },
  watch: {
    myProfile: {
      handler() {
        if (this.myProfile) {
          this.fillForm(this.myProfile)
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    driverTypes,
    ...mapGetters(['currentLanguage']),
    ...mapGetters({
      myProfile: 'auth/myProfile',
      updatePhoneLoading: 'auth/updatePhoneLoading',
      updatePhoneErrors: 'auth/updatePhoneErrors'
    }),
    phoneErrors(){
      const errors = []
      if(this.updatePhoneErrors){
        if(this.updatePhoneErrors.status === 404){
          errors.push(this.$t(`validationError_phone_${this.updatePhoneErrors.data.data.type}`))
        }
        if(this.updatePhoneErrors.status === 422){
          errors.push(this.$t(`validationError_phone_${this.updatePhoneErrors.data.data.phone[0]}`))
        }
      }else{
        return []
      }
      return errors
    }
  },
  mounted() {
    this.setDatePickerCurrentLocale()
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
      confirmOtp: 'auth/confirmEmailOtp',
      getMyProfile: 'auth/getProfile',
      updateMyProfile: 'auth/updateProfile',
      updatePhone: 'auth/updatePhone',
    }),
    fillForm(data) {
      Object.entries(data).forEach(([key, value]) => {
        if (key === 'phone' && value && value.length >= 15) {
        }else{
          if (this.payload.hasOwnProperty(key)) this.payload[key] = value
        }
      })
    },
    formattedDate(format) {
      return moment(new Date()).format(format)
    },
    async handleUpdatePhone(){
      if(this.payload.phone !== this.myProfile.phone){
        await this.updatePhone({phone: this.payload.phone})
      }
    },
    async submitForm() {
      this.serverErrors = {}
      this.$refs.form.validate()
      if (this.validForm) {
        try {
          if (this.myProfile?.email !== this.payload.email) {
            this.loading = true
            await this.signIn({
              email: this.payload.email,
              lang: this.currentLanguage.requestKey,
              device: {
                id: '73617948-6f6c-6120-426c-6f6720777000',
                type: 'web'
              }
            })
            this.showConfirmOpt = true
          } else {
            await this.handleUpdatePhone()
            await this.handleUpdateMyProfile()
          }
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false
        }
      }
    },
    async uploadMedia() {
      const imgLink = await apiMedia.upload(this.payload.avatarLink)
      this.payload.avatarLink = imgLink
    },
    async handleUpdateMyProfile() {
      this.serverErrors = {}
      this.loading = true
      try {
        if (this.payload.avatarLink.includes('base64')) {
          await this.uploadMedia()
        }
        this.payload.accountTypes = [101]
        await this.updateMyProfile(this.payload)
        this.$emit('submit', {
          driverType: this.payload.driverType,
          phone: this.payload.phone,
          profilePhoto: this.payload.avatarLink,
          userId: this.myProfile.userId
        })
        this.showConfirmOpt = false
        this.$emit('forward')
      } catch (err) {
        if (err.errorType === "existence") {
          this.serverErrors = {
            [err.data.field]: this.$t(`serverError_${err.data.type}`)
          }
        } else {
          this.serverErrors = err?.data
        }
      } finally {
        this.loading = false
      }
    },
    async submitEmail(email) {
      try {
        this.loadingEmail = true
        await this.signIn({
          email: email,
          lang: this.currentLanguage.requestKey,
          device: {
            id: '73617948-6f6c-6120-426c-6f6720777000',
            type: 'web'
          }
        })
        this.payload.email = email
        this.showConfirmEmail = false
        this.showConfirmOpt = true
        this.hasHolaProfile = true
      } finally {
        this.loadingEmail = false
      }
    },
    async submitOtp(code) {
      this.loadingOtp = true
      this.confirmOtpServerErrors = null
      try {
        const response = await this.confirmOtp({
          email: this.payload.email,
          otp: code,
          device
        })
        if (this.hasHolaProfile) {
          this.showConfirmOpt = false
          this.fillForm(response)
          await this.handleUpdatePhone()
        } else {
          this.showConfirmOpt = false
          await this.handleUpdatePhone()
          await this.handleUpdateMyProfile()
        }
        this.hasHolaProfile = false
      } catch (err) {
        this.confirmOtpServerErrors = err?.data
      } finally {
        this.loadingOtp = false
      }
    },
    setDatePickerCurrentLocale() {
      this.localePicker = this.currentLanguage.code
    },
  }
}
</script>
