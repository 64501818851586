<template>
  <v-form class="form"
          v-model="validForm"
          @submit.prevent="submitForm"
          ref="form">
    <div class="mobile-title">
      {{ $t('stepVehicleInfo') }}
    </div>
    <v-row class="mb-1 mt-1">
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldLicensePlateNumberLabel') }}
          </div>
          <div class="form-field__body">
            <v-text-field
              v-model="payload.licensePlateNumber"
              :error-messages="serverErrors.licensePlateNumber"
              class="mt-0 pt-0"
              prepend-inner-icon="mdi-pencil"
              name="name"
              type="text"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldYearVehicleLabel') }}
          </div>
          <div class="form-field__body datepicker">
            <v-text-field
              v-model="payload.vehicleYear"
              :error-messages="serverErrors.vehicleYear"
              class="mt-0 pt-0"
              :rules="yearVehicleRules"
              prepend-inner-icon="mdi-pencil"
              name="name"
              type="number"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('photoVehicleLicensePlate') }}
          </div>
          <div class="form-field__body">
            <ImageFile
              image-id="vehiclePhoto"
              :image="payload.vehiclePhoto"
              v-model="payload.vehiclePhoto">
            </ImageFile>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('photoVehicleRegistrationCardFront') }}
          </div>
          <div class="form-field__body">
            <ImageFile
              image-id="registrationCardPhotoFront"
              :image="payload.registrationCardPhotoFront"
              v-model="payload.registrationCardPhotoFront">
            </ImageFile>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('photoVehicleRegistrationCardBack') }}
          </div>
          <div class="form-field__body">
            <ImageFile
              image-id="registrationCardPhotoBack"
              :image="payload.registrationCardPhotoBack"
              v-model="payload.registrationCardPhotoBack">
            </ImageFile>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between align-center">
      <v-btn
        small
        color="silver"
        @click="$emit('backward')"
      >
        <v-icon left small>
          mdi-chevron-left
        </v-icon>
        {{ $t('back') }}
      </v-btn>
      <div>
        <v-btn
          small
          color="silver"
          class="mr-2 mr-sm-3"
          @click="$emit('forward')"
        >
          {{ $t('skip') }}
          <v-icon right small>
            keyboard_double_arrow_right
          </v-icon>
        </v-btn>
        <v-btn
          small
          type="submit"
          :loading="loading"
          color="secondary"
        >
          {{ $t('continue') }}
          <v-icon right small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
    </div>
  </v-form>
</template>
<script>
import ImageFile from "@/components/features/ImageFile.vue";
import validation from '@/mixins/validation'
import apiMedia from "@/api/media";

export default {
  mixins: [validation],
  components: {
    ImageFile
  },
  data() {
    return {
      serverErrors: {},
      loading: false,
      validForm: false,
      payload: {
        licensePlateNumber: '',
        vehicleYear: '',
        vehiclePhoto: '',
        registrationCardPhotoFront: '',
        registrationCardPhotoBack: '',
      }
    }
  },
  methods: {
    async uploadMedia(base64){
      const imgLink = await apiMedia.upload(base64)
      return imgLink
    },
    async submitForm() {
      this.serverErrors = {}
      this.$refs.form.validate()
      if (this.validForm) {
        try {
          this.loading = true
          if (this.payload.vehiclePhoto.includes('base64')) {
            this.payload.vehiclePhoto = await this.uploadMedia(this.payload.vehiclePhoto)
          }
          if (this.payload.registrationCardPhotoFront.includes('base64')) {
            this.payload.registrationCardPhotoFront = await this.uploadMedia(this.payload.registrationCardPhotoFront)
          }
          if (this.payload.registrationCardPhotoBack.includes('base64')) {
            this.payload.registrationCardPhotoBack = await this.uploadMedia(this.payload.registrationCardPhotoBack)
          }
          this.$emit('submit', this.payload)
          this.$emit('forward')
        }finally {
          this.loading = false
        }
      }
    },
  }
}
</script>
