<template>
  <div class="main-form mt-8">
    <div class="m-container registration">
      <h1 class="text-center mb-5">
        {{ $t('registration') }}
      </h1>
      <div class="main-form__wrap large-desc">
        <div class="main-form__left">
          <h5>{{ $t('registrationTitle') }}</h5>
          <p>
            {{ $t('registrationDesc1') }}
            <br><br>
            {{ $t('registrationDesc2') }}
            <br><br>
            {{ $t('registrationDesc3') }}
            <br><br>
            {{ $t('registrationDesc4') }}
            <br><br>
            {{ $t('registrationDesc5') }}
            <br><br>
            {{ $t('registrationDesc6') }}
            <br><br>
            {{ $t('registrationDesc7') }}
            <br><br>
            {{ $t('registrationDesc8') }}
            <br><br>
            {{ $t('registrationDesc9') }}
            <br><br>
            {{ $t('registrationDesc10') }}
            <br><br>
            {{ $t('registrationDesc11') }}
          </p>
        </div>
        <div class="main-form__right">
          <v-stepper
            v-model="step"
            alt-labels
            transition="none"
            class="elevation-0"
            color="lightBackground">
            <v-stepper-header transition="none" class="elevation-0">
              <v-stepper-step
                color="secondary"
                style="white-space: nowrap"
                class="pb-0"
                :complete="step > 1"
                step="1"
              >
                {{ $t('stepYourInfo') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                class="pb-0"
                style="white-space: nowrap"
                color="secondary"
                :complete="step > 2"
                step="2"
              >
                {{ $t('stepLicense') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                class="pb-0"
                style="white-space: nowrap"
                color="secondary"
                :complete="step > 3"
                step="3">
                {{ $t('stepIdentification') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                class="pb-0"
                style="white-space: nowrap"
                color="secondary"
                :complete="step > 4"
                step="4">
                {{ $t('stepVehicleInfo') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                class="pb-0"
                style="white-space: nowrap"
                color="secondary"
                :complete="step > 5"
                step="5">
                {{ $t('stepPoliceReport') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                class="pb-0"
                style="white-space: nowrap"
                color="secondary"
                :complete="step > 6"
                step="6">
                {{ $t('stepAcceptance') }}
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items color="lightBackground" class="pt-sm-4">
              <v-stepper-content step="1" class="pa-0">
                <HolaForm
                  @forward="handleStep(step++)"
                  @submit="handleUpdateMainForm"/>
              </v-stepper-content>
              <v-stepper-content step="2" class="pa-0">
                <LicenseForm
                  @forward="handleStep(step++)"
                  @backward="handleStep(step--)"
                  @submit="handleUpdateMainForm"/>
              </v-stepper-content>
              <v-stepper-content step="3" class="pa-0">
                <IdentificationForm
                  @forward="handleStep(step++)"
                  @backward="handleStep(step--)"
                  @submit="handleUpdateMainForm"/>
              </v-stepper-content>
              <v-stepper-content step="4" class="pa-0">
                <VehicleForm
                  @forward="handleStep(step++)"
                  @backward="handleStep(step--)"
                  @submit="handleUpdateMainForm"/>
              </v-stepper-content>
              <v-stepper-content step="5" class="pa-0">
                <PoliceReportForm
                  @forward="handleStep(step++)"
                  @backward="handleStep(step--)"
                  @submit="handleUpdateMainForm"/>
              </v-stepper-content>
              <v-stepper-content step="6" class="pa-0">
                <AcceptanceForm
                  :loading="driverLoading"
                  :countries="countries"
                  @backward="handleStep(step--)"
                  @submit="handleCreateDriver"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AcceptanceForm from "@/components/views/registration/AcceptanceForm.vue";
import HolaForm from "@/components/views/registration/HolaForm.vue";
import LicenseForm from "@/components/views/registration/LicenseForm.vue";
import IdentificationForm from "@/components/views/registration/IdenteficationForm.vue";
import VehicleForm from "@/components/views/registration/VehicleForm.vue";
import PoliceReportForm from "@/components/views/registration/PoliceReportForm.vue";

import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    AcceptanceForm,
    HolaForm,
    LicenseForm,
    IdentificationForm,
    VehicleForm,
    PoliceReportForm
  },
  data() {
    return {
      step: 1,
      despaxoForm: {
        // your info
        userId: null,
        phone: "",
        profilePhoto: "",
        driverType: "",
        // license
        licenseNumber: "",
        licensePhotoNFace: "",
        licensePhotoFront: "",
        licensePhotoBack: "",
        // identification
        idNumber: "",
        idPhotoFront: "",
        idPhotoBack: "",
        // vehicle
        licensePlateNumber: "",
        vehiclePhoto: "",
        vehicleYear: '',
        registrationCardPhotoFront: "",
        registrationCardPhotoBack: "",
        // police report
        backgroundCheck: "",
        // acceptance
        countryId: "",
        signature: "",
        // others
        address: "none",
        messenger: 0,
        sponsorHolaId: this.$route.query?.sponsorHolaId
      }
    }
  },
  computed: {
    ...mapGetters({
      myProfile: 'auth/myProfile',
      countries: 'info/countries',
      driver: 'driver/currentItem',
      driverLoading: 'driver/loading'
    })
  },
  watch: {
    driver: {
      async handler() {
        if (this.driver && this.myProfile) {
          await this.$router.push({name: 'dashboard'})
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // clearTokens()
    this.fetchCountries()
  },
  methods: {
    ...mapActions({
      createDriver: 'driver/create',
      fetchCountries: 'info/fetchCountries',
      createContactInfo: 'info/createContactInfo',
    }),
    handleStep() {
      this.$vuetify.goTo(0)
    },
    handleUpdateMainForm(data) {
      Object.assign(this.despaxoForm, data)
    },
    async handleCreateDriver(data) {
      Object.assign(this.despaxoForm, data)
      const payload = {}
      Object.entries(this.despaxoForm).forEach(([key, value]) => {
        if(value){
          payload[key] = value
        }
      })
      payload.messenger = 0
      payload.driverType = this.despaxoForm.driverType
      await this.createDriver(payload)
      this.createContactInfo({
        phone: this.despaxoForm.phone,
        country: this.despaxoForm.countryId
      })
      await this.$router.push({name: 'dashboard'})
    }
  }
}
</script>
<style lang="scss">
.registration {
  .v-stepper__label{
    max-width: 120px;
    white-space: initial;
    text-align: center;
  }
  @include respondTo(1150px) {
    .v-stepper__label {
      display: none;
    }
  }

  .mobile-title {
    font-size: 20px;
    font-weight: 600;
    display: none;
    text-align: center;
    @include respondTo(1150px) {
      display: block;
    }
  }

  &-wrap {
    background: $white;
  }
}
</style>
