<template>
  <v-form class="form"
          v-model="validForm"
          @submit.prevent="submitForm"
          ref="form">
    <div class="mobile-title">
      {{ $t('stepIdentification') }}
    </div>
    <v-row class="mb-1 mt-1">
      <v-col cols="12" sm="12" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldIdNumberLabel') }}
          </div>
          <div class="form-field__body">
            <v-text-field
              v-model="payload.idNumber"
              class="mt-0 pt-0"
              :rules="required"
              prepend-inner-icon="mdi-pencil"
              name="idNumber"
              type="text"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldPhotoFrontLabel') }}
          </div>
          <div class="form-field__body">
            <ImageFile
              image-id="idPhotoFront"
              :image="payload.idPhotoFront"
              v-model="payload.idPhotoFront">
            </ImageFile>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldIdConfirmationLabel') }}
          </div>
          <div class="form-field__body">
            <ImageFile
              image-id="idPhotoBack"
              :image="payload.idPhotoBack"
              v-model="payload.idPhotoBack">
            </ImageFile>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between align-center">
      <v-btn
        small
        color="silver"
        @click="$emit('backward')"
      >
        <v-icon left small>
          mdi-chevron-left
        </v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-btn
        small
        type="submit"
        :disabled="!payload.idPhotoFront || !payload.idPhotoBack"
        :loading="loading"
        color="secondary"
      >
        {{ $t('continue') }}
        <v-icon right small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import ImageFile from "@/components/features/ImageFile.vue";
import validation from '@/mixins/validation'
import {mapGetters} from 'vuex'
import moment from 'moment'
import apiMedia from "@/api/media";

export default {
  mixins: [validation],
  components: {
    ImageFile
  },
  data() {
    return {
      serverErrors: {},
      loading: false,
      validForm: false,
      payload: {
        idNumber: '',
        idPhotoFront: '',
        idPhotoBack: '',
      }
    }
  },
  methods: {
    async uploadMedia(base64){
      const imgLink = await apiMedia.upload(base64)
      return imgLink
    },
    async submitForm() {
      this.serverErrors = {}
      this.$refs.form.validate()
      if (this.validForm) {
        try {
          this.loading = true
          if (this.payload.idPhotoFront.includes('base64')) {
            this.payload.idPhotoFront = await this.uploadMedia(this.payload.idPhotoFront)
          }
          if (this.payload.idPhotoBack.includes('base64')) {
            this.payload.idPhotoBack = await this.uploadMedia(this.payload.idPhotoBack)
          }
          this.$emit('submit', this.payload)
          this.$emit('forward')
        }finally {
          this.loading = false
        }
      }
    },
  }
}
</script>
