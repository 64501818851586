<template>
  <div class="pa-1 white full-width pb-4">
    <v-form class="form"
            v-model="validForm"
            @submit.prevent="submitForm"
            ref="form">
      <div class="text-center" style="font-size: 26px; font-weight: 600;">
        {{ $t('signIn') }}
      </div>
      <v-row class="mb-1 mt-5 ">
        <v-col cols="12" class="pt-0 pb-0">
          <div class="form-field">
            <div class="form-field__label">
              {{ $t('fieldEmailLabel') }}
            </div>
            <div class="form-field__body">
              <v-text-field
                v-model.trim="email"
                :error-messages="serverErrors?.email"
                class="mt-0 pt-0"
                :rules="emailRules"
                prepend-inner-icon="mdi-pencil"
                name="email"
                type="text"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center align-center mt-4">
        <v-btn
          type="submit"
          :loading="loading"
          color="secondary"
        >
          {{ $t('confirm') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import validation from '@/mixins/validation'

export default {
  props: {
    serverErrors: {},
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [validation],
  emits: ['submit', 'reset-errors'],
  data() {
    return {
      validForm: false,
      email: ''
    }
  },
  methods: {
    submitForm() {
      this.$refs.form.resetValidation()
      this.$emit('reset-errors')
      setTimeout(() => {
        this.$refs.form.validate()
        if (this.validForm) {
          this.$emit('submit', this.email)
        }
      }, 10)
    }
  },
  beforeDestroy() {
    this.$refs.form.resetValidation()
    this.$emit('reset-errors')
  }
}
</script>
<style lang="scss">
</style>
